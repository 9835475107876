let mailboxItem;
let apiToken;

Office.onReady((info) => {
  if (info.host === Office.HostType.Outlook) {
    // Ensure mailbox is available
    if (Office.context.mailbox) {
      mailboxItem = Office.context.mailbox.item;

      // Check if the ApiToken is already set in RoamingSettings
      apiToken = Office.context.roamingSettings.get("apiToken");
      console.log("ApiToken retrieved from RoamingSettings: " + apiToken);
    } else {
      console.error("Office.context.mailbox is not available.");
      //document.getElementById("statusMessage").innerText = "Office.context.mailbox is not available.";
    }
  } else {
    console.error("Not running in Outlook.");
    //document.getElementById("statusMessage").innerText = "Not running in Outlook.";
  }
});

// Entry point for Message Body Checker add-in before send is allowed.
// <param name="event">MessageSend event is automatically passed by BlockOnSend code to the function specified in the manifest.</param>
function validateBody(event) {
  if (mailboxItem) {
    mailboxItem.body.getAsync("text", { asyncContext: event }, checkBodyOnlyOnSendCallBack);
  } else {
    // Log or handle the case where mailboxItem is not available
    event.completed({ allowEvent: true });
  }
}

// Check if the body contains a specific set of blocked words. If it contains the blocked words, block email from being sent. Otherwise allows sending.
// <param name="asyncResult">MessageSend event passed from the calling function.</param>
function checkBodyOnlyOnSendCallBack(asyncResult) {
  openDialog(asyncResult.asyncContext, asyncResult.value);
}

// Open an HTML dialog to notify the user about blocked words and prompt them to take action.
function openDialog(eventContext, value) {
  const dialogOptions = { width: 50, height: 45, displayInIframe: true };
  // Open the dialog
  Office.context.ui.displayDialogAsync(
    `https://outlook.1protection.ai/scan-dialog.html`,
    dialogOptions,
    function (asyncResult) {
      const dialog = asyncResult.value;

      // Listen for the dialogReady message
      dialog.addEventHandler(Office.EventType.DialogMessageReceived, function (message) {
        if (message?.message === "dialogReady") {
          // Send a message to the dialog
          dialog.messageChild(
            JSON.stringify({
              apiToken: Office.context.roamingSettings.get("apiKey"), // Use the apiToken from the roaming settings or elsewhere
              message: value,
            })
          );
        }

        const response = JSON.parse(message?.message);
        console.log(response);

        if (response.status === "ok") {
          // If response.message is present, set response.message as email body
          if (response.message) {
            const escapedMessage = response.message.replace(/</g, "&lt;").replace(/>/g, "&gt;");

            mailboxItem.body.setAsync(escapedMessage, { coercionType: "html" }, function (asyncResult) {
              if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                console.log("Email body successfully updated.");
              } else {
                console.error("Failed to update email body: " + asyncResult.error.message);
              }
            });
          }

          // Allow the email to be sent and close the dialog
          eventContext.completed({ allowEvent: true });
          dialog.close();
        } else if (response.status === "cancel") {
          // Block the email send and close the dialog
          mailboxItem.notificationMessages.addAsync("NoSend", {
            type: "errorMessage",
            message:
              "1Protection.AI found sensitive data in your input. Your organisation requires you to review this email before it is submitted.",
          });

          // Disallow the email to be sent and close the dialog
          eventContext.completed({ allowEvent: false });
          dialog.close();
        }
      });

      dialog.addEventHandler(Office.EventType.DialogEventReceived, function () {
        // If the dialog is closed without an action, block the send event
        mailboxItem.notificationMessages.addAsync("NoSend", {
          type: "errorMessage",
          message:
            "1Protection.AI found sensitive data in your input. Your organisation requires you to review this email before it is submitted.",
        });
        eventContext.completed({ allowEvent: false });
        dialog.close();
      });
    }
  );
}
